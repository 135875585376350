import React from 'react';
import { FourOhFour } from 'gatsby-theme-carbon';

const links = [
  { href: '/company/about', text: 'About' },
  { href: '/contact', text: 'Contact' },
];

const Custom404 = () => <FourOhFour links={links} />;

export default Custom404;
